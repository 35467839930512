<template>
  <config-area title="Global" category="global" />
</template>

<script>
import ConfigArea from '@/components/ConfigArea.vue'
export default {
  components: {ConfigArea}
}
</script>


    ConfigArea<style scoped>
>>> .form-group label {color:rgb(67, 90, 219)}
</style>>

